:root {
    --black: #000;
    --red: #f00;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

html {
    font-size: 100%;
    width: 100vw;
    overflow-x: hidden;
}

#root {
    position: relative;
}

a {
    color: inherit;
    text-decoration: none;
}

.loading_Loading__1te60 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
}

@keyframes loading_bounce__1-xJ3 {
    0% {
        padding-bottom: 0;
    }
    50% {
        padding-bottom: 24px;
    }
    100% {
        padding-bottom: 0;
    }
}

.loading_acorn__3zMgz {
    width: 36px;
    height: auto;
    animation: loading_bounce__1-xJ3 1s ease;
    animation-iteration-count: infinite;
}

.loading_acorn__3zMgz:nth-child(1) {
    animation-delay: calc(1s / 6);
}
.loading_acorn__3zMgz:nth-child(2) {
    animation-delay: calc(2s / 6);
}
.loading_acorn__3zMgz:nth-child(3) {
    animation-delay: calc(3s / 6);
}

.carousel_wrap__2wsOT {
    position: relative;
    overflow: hidden;
    width: 160rem;
    margin: 0 calc(50% - 80rem);
    height: 62.4rem;
}

.carousel_btn__Isz56 {
    position: absolute;
    top: 0;

    height: 100%;
    display: flex;
    align-items: center;

    cursor: pointer;
    z-index: 10;
}

@media (max-width: 440px) {
    .carousel_btn__Isz56 {
        display: none;
    }
}

.carousel_btnIcon__1XQPG {
    width: 3.56rem;
    height: 4.19rem;
}

.carousel_btnIcon__1XQPG:hover {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.carousel_nextBtn__vEO9R {
    right: 5.87rem;
}

.carousel_prevBtn__1nqfI {
    left: 5.87rem;
}

.carousel_slide__2Ogyx {
    position: absolute;
    top: -10000px;

    height: 100%;
    width: 100%;

    transition: left 0.2s;
}

.carousel_slide__2Ogyx.carousel_next__1JEg2 {
    top: 0;
    left: 100vw;
}

.carousel_slide__2Ogyx.carousel_curr__3YurT {
    top: 0px;
    left: 0;
    z-index: 9;
}

.carousel_slide__2Ogyx.carousel_prev__pbHqJ {
    top: 0;
    left: -100vw;
}

.carousel_indicatorWrap__3aNBL {
    position: absolute;
    bottom: 0;
    z-index: 11;
    width: 100%;
    text-align: center;
}

@media (max-width: 440px) {
    .carousel_indicatorWrap__3aNBL {
        display: none;
    }
}

.carousel_indicator__3_RGo {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    border: 0.25rem solid #9d9d9d;
    display: inline-block;
    margin: 0.25rem 0.25rem 4.5rem 0.25rem;
    cursor: pointer;
}

.carousel_indicator__3_RGo.carousel_selected__3vAiy {
    background-color: #9d9d9d;
}

.carousel_pageNumber__D1WI_ {
    display: none;
}

@media (max-width: 440px) {
    .carousel_pageNumber__D1WI_ {
        display: block;

        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;

        width: 48px;
        height: 20px;

        margin: 15px;

        background: rgba(0, 0, 0, 0.3);
        border-radius: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        font: 14px "nsw";
        color: #fff;
    }
}

.title_title__1RAGW {
    font: 10.3rem "nsb";
    text-align: center;
    margin-bottom: 9.68rem;
}

@media (max-width: 440px) {
    .title_title__1RAGW {
        font: 40px "nsb";
        text-align: center;
        margin-bottom: 40px;
    }
}

.home_home__QpCMY > *:first-child {
    margin-bottom: 15.75rem;
}

.home_home__QpCMY > * {
    margin-bottom: 20rem;
}

.home_intro__3eXLe {
    text-align: center;
    padding: 0 48px;
}
@media (max-width: 440px) {
    .home_intro__3eXLe {
        text-align: center;
        padding: 0;
    }
}

.home_moto__3tsRh {
    font: 3rem "nsw";
    margin-bottom: 0.93vw;
}
@media (max-width: 440px) {
    .home_moto__3tsRh {
        font: bold 15px "nsw";
        padding: 0 16px;
    }
}

.home_companyName__2FNPN {
    font: bold 4.5rem "nsw";
    margin-bottom: 5rem;
}
@media (max-width: 440px) {
    .home_companyName__2FNPN {
        font: bold 24px "nsw";
        margin-bottom: 40px;
    }
}

.home_desc1__1V4Wi,
.home_desc2__1SQnD,
.home_desc3__c_A7s {
    font: lighter 3rem "hn";
    margin-bottom: 1rem;
}
@media (max-width: 440px) {
    .home_desc1__1V4Wi,
    .home_desc2__1SQnD,
    .home_desc3__c_A7s {
        font: 14px "hn";
        line-height: 20px;
        padding: 0 24px;
    }
}

.home_businessImgWrap__1rExX {
    width: 124.63rem;
}
@media (max-width: 440px) {
    .home_businessImgWrap__1rExX {
        width: auto;
        height: 160px;
        overflow: scroll hidden;
    }
    .home_businessImgWrap__1rExX img {
        margin: 0 16px;
        height: 100%;
        width: auto !important;
    }
}

.home_newsWrap__C-cF1 {
    display: flex;
    justify-content: center;
    grid-gap: 4.63rem;
    gap: 4.63rem;
    width: 100vw;
    display: flex;
    justify-content: center;
}

@media (max-width: 440px) {
    .home_newsWrap__C-cF1 {
        padding: 16px;
        flex-direction: column;
        box-sizing: border-box;
    }
}

.brandList_wrap__1iBbB {
    text-align: center;
    display: flex;
    justify-content: center;
}

@media (min-width: 441px) {
    .brandList_brandList__2STtK {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 106.7rem;
        grid-gap: 5rem;
        gap: 5rem;
    }

    .brandList_imgWrap__aHh46 {
        display: flex;
        position: relative;
        height: 100%;
    }
    .brandList_item__GgH8q {
        width: 100%;
        height: 0;
        padding-bottom: 111.54%;
        overflow: hidden;
        position: relative;
    }

    .brandList_imgWrap__aHh46 {
        display: flex;
        position: relative;
        height: 100%;
    }

    .brandList_imgWrap__aHh46 > img {
        position: absolute;
        top: 0;
        left: 0;
    }

    .brandList_link__3HRzL {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .brandList_item__GgH8q:hover img {
        position: absolute;
        top: -5%;
        left: -5%;
        width: 110% !important;
        transition: all 0.3s;
    }

    .brandList_link__3HRzL {
        background-color: #006ab7;
        opacity: 0.7;
        color: #fff;
        font: bold 2.5rem "nsw";
        line-height: 1.2;
        display: flex;
        padding: 0.7vw;
        box-sizing: border-box;
        transition: all 0.3s;
    }

    .brandList_link__3HRzL * {
        margin: auto;
    }

    .brandList_item__GgH8q:not(:hover) .brandList_link__3HRzL {
        opacity: 0;
    }
}

@media (max-width: 440px) {
    .brandList_wrap__1iBbB {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 16px;
    }
    .brandList_brandList__2STtK {
        display: grid;
        width: 100%;
        grid-gap: 32px 16px;
        gap: 32px 16px;
        grid-template-columns: 1fr 1fr;
    }
    .brandList_item__GgH8q {
        position: relative;
        position: relative;
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        gap: 16px;
    }
    .brandList_link__3HRzL {
        display: none;
    }
    .brandList_brandName__3Nf68 {
        font: 500 14px "nsw";
        text-align: left;
    }
}

.newsThumbnail_wrap__o-v6W:hover {
    text-decoration: underline;
}

.newsThumbnail_title__15lEf {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* --------- home ----------*/

.newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF {
    width: 50rem;
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF .newsThumbnail_title__15lEf {
    margin: 2.9rem 0 2rem 0;
    font-family: "hn";
    font-size: 2.56rem;
    line-height: 1.3;
    -webkit-text-size-adjust: none;
       -moz-text-size-adjust: none;
            text-size-adjust: none;
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF .newsThumbnail_date__1arvm {
    font-family: "hn";
    font-size: 1.75rem;
    color: #636363;
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF .newsThumbnail_imgWrap__294Ij {
    width: 100%;
    aspect-ratio: 1 / 1;
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF .newsThumbnail_imgWrap__294Ij img {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
}

@media (max-width: 440px) {
    .newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 146px 1fr;
        grid-gap: 12px;
        gap: 12px;
        grid-template-areas:
            "img title"
            "img date";
    }

    .newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF .newsThumbnail_imgWrap__294Ij {
        grid-area: img;
    }

    .newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF .newsThumbnail_title__15lEf {
        font-family: "hn";
        font-size: 14px;
        line-height: 1.3;

        grid-area: title;
        align-self: end;
    }

    .newsThumbnail_wrap__o-v6W.newsThumbnail_home__2OIOF .newsThumbnail_date__1arvm {
        font-family: "hn";
        font-size: 12px;
        color: #636363;

        grid-area: date;
        align-self: start;
    }
}

/* --------- brand Related ----------*/
.newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy {
    width: 18.28vw;
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy .newsThumbnail_imgWrap__294Ij {
    aspect-ratio: 1 / 1;
    margin-bottom: 1vw;
}
.newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy .newsThumbnail_imgWrap__294Ij img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy .newsThumbnail_title__15lEf {
    font: lighter 1.87rem "ms";
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy .newsThumbnail_date__1arvm {
    display: none;
}

@media (max-width: 440px) {
    .newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 2fr 3fr;
        grid-gap: 12px;
        gap: 12px;
        grid-template-areas:
            "img title"
            "img date";
    }

    .newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy .newsThumbnail_imgWrap__294Ij {
        grid-area: img;
        height: auto;
    }

    .newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy .newsThumbnail_title__15lEf {
        font-family: "ms";
        font-size: 14px;
        line-height: 1.3;

        grid-area: title;
        align-self: end;
    }

    .newsThumbnail_wrap__o-v6W.newsThumbnail_brandRelated__2kTJy .newsThumbnail_date__1arvm {
        display: block;
        font-family: "hn";
        font-size: 12px;
        color: #636363;

        grid-area: date;
        align-self: start;
    }
}

/* --------- news page ----------*/

.newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr {
    width: 39.5rem;
    display: flex;
    flex-direction: column;
}
@media (max-width: 440px) {
    .newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr {
        width: 100%;
    }
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr .newsThumbnail_imgWrap__294Ij {
    aspect-ratio: 1 / 1;
    width: 100%;
}
@media (max-width: 440px) {
    .newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr .newsThumbnail_imgWrap__294Ij {
        width: calc(100vw - 32px);
    }
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr .newsThumbnail_imgWrap__294Ij img {
    object-fit: cover;
    height: 100%;
}
@media (max-width: 440px) {
    .newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr .newsThumbnail_imgWrap__294Ij img {
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    }
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr .newsThumbnail_title__15lEf {
    font: 2.31rem "hn";
    margin: 2rem 0;
    word-break: keep-all;
}
@media (max-width: 440px) {
    .newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr .newsThumbnail_title__15lEf {
        font-size: 16px;
        margin: 24px 0 16px 0;
    }
}

.newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr .newsThumbnail_date__1arvm {
    font: 1.62rem "hn";
    color: #636363;
    margin-top: auto;
}
@media (max-width: 440px) {
    .newsThumbnail_wrap__o-v6W.newsThumbnail_newsPage__3jfgr .newsThumbnail_date__1arvm {
        font-size: 12px;
    }
}

@media (max-width: 440px) {
    .footer_footer__1H_GV {
        background-color: #3c3c3c;
        color: #fff;
        display: flex;
        flex-direction: column;
        grid-gap: 40px;
        gap: 40px;

        padding: 32px 16px;

        font: lighter 14px "hn";
    }
    .footer_menuList__EFSCt {
        margin-bottom: 40px;
    }
    .footer_menuItem__2mH3K {
        padding: 14.5px 0;
        border-bottom: 0.5px solid rgb(255, 255, 255);
    }
    .footer_dropDownBtn__3ruHg {
        float: right;
        margin-right: 8px;
        transition: transform 0.5s;
    }
    .footer_dropDownBtn__3ruHg.footer_open__1kM6v {
        transform: rotateX(180deg);
    }
    .footer_brandDetailDropdown__6r9B5 {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        grid-gap: 8px;
        gap: 8px;
        padding: 0 0 0 24px;
    }
    .footer_brandDetailDropdown__6r9B5.footer_open__1kM6v {
        padding: 8px 0 0 24px;
    }
    .footer_policyItem__Q0b8l {
        display: inline-block;
        width: 50%;
    }
    .footer_sns__3Xg6j {
        display: flex;
        grid-gap: 8px;
        gap: 8px;
        align-items: center;
    }
    .footer_sns__3Xg6j img {
        width: 24px;
    }
    .footer_companyInfo__ma5ev {
        font: lighter 12px "ms";
        margin-top: 40px;
    }
    .footer_copyright__3CVL2 {
        margin-top: 40px;
    }
}

@media (min-width: 440px) {
    .footer_footer__1H_GV {
        background-color: #3c3c3c;
        color: #fff;

        font: lighter 1.88rem "hn";

        --menu-column: 4;
        --policy-column: 1;
        --total-column: 5;
    }
    .footer_section1__2_bae {
        padding: 5rem 0;
        --width: calc(var(--total-column) * 18rem);
        width: var(--width);
        margin: 0 calc((100% - var(--width)) / 2);
    }
    .footer_menuList__EFSCt {
        width: calc(var(--menu-column) / var(--total-column) * 100%);
        grid-auto-flow: column;

        display: inline-grid;
        grid-template-columns: repeat(var(--menu-column), 1fr);
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: column;
        grid-gap: 3.57rem 0;
        gap: 3.57rem 0;
    }
    .footer_policies__3IoC6 {
        width: calc(var(--policy-column) / var(--total-column) * 100%);

        display: inline-grid;
        grid-template-columns: repeat(var(--policy-column), 1fr);
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: column;
        grid-gap: 3.57rem 0;
        gap: 3.57rem 0;
    }
    .footer_Hr__2LeWA {
        height: 0.06rem;
        background-color: #d9d9d9;
    }
    .footer_section2__2lG2- {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 4.16rem 0;
        width: 108.75rem;
        margin: 0 calc((100% - 108.75rem) / 2);
        font: lighter 1.5rem "hn";
    }
    .footer_address__iKVBZ,
    .footer_representative__1uEOR {
        display: inline-block;
        margin-bottom: 1em;
    }
    .footer_address__iKVBZ {
        margin-right: 5rem;
    }
    .footer_copyright__3CVL2 {
    }
    .footer_sns__3Xg6j {
        display: flex;
        grid-gap: 0.96rem;
        gap: 0.96rem;
        align-items: center;
    }
    .footer_sns__3Xg6j img {
        width: 2.5rem;
    }
}

.footer_youtube__1gaKx,
.footer_instagram__iVE0a {
    /* display: none; */
}

.DropDown_wrap__1PY1M {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, padding 0.5s ease;
}

.DropDown_wrap__1PY1M.DropDown_open__2N6-F {
    max-height: 100vw;
}

/* Modile */
@media (max-width: 440px) {
    .menu_menu__3Lm9e {
        padding: 40px 16px;
        width: calc(100vw - 32px);
    }
    .menu_menu__3Lm9e.menu_menuOpen__r3N6y {
        top: 56px;
    }

    .menu_menuWrap__3P8Gj {
        width: 100%;
        grid-gap: 24px;
        gap: 24px;
        font: 500 24px "nsw";
    }
    .menu_menuItem__3SSq6 {
        width: 100%;
        grid-gap: 0 12px;
        gap: 0 12px;
        flex-wrap: wrap;
    }
    .menu_menuItem__3SSq6 .menu_dropDownBtn__999bA {
        margin-left: auto;
    }
    .menu_brandDetailDropdown__1Zo-X {
        width: 100%;

        padding: 0 0 0 42px;
        font: 500 14px "nsw";
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
    }
    .menu_brandDetailDropdown__1Zo-X.menu_open__Vysm_ {
        padding: 12px 0 0 42px;
    }
    .menu_dropDownBtn__999bA img {
        transition: transform 0.5s;
    }
    .menu_dropDownBtn__999bA.menu_open__Vysm_ img {
        transform: rotateX(180deg);
    }
    .menu_menuItemImg__1_Yt3 {
        width: 28px;
    }
}

/* Desktop */
@media (min-width: 441px) {
    .menu_menu__3Lm9e {
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    .menu_menu__3Lm9e.menu_menuOpen__r3N6y {
        top: 0;
    }
    .menu_menuWrap__3P8Gj {
        width: 43.75rem;
        grid-gap: 3.83rem;
        gap: 3.83rem;
    }
    .menu_menuItem__3SSq6 {
        grid-gap: 1.87rem;
        gap: 1.87rem;
        font-size: 4.75rem;
        font-family: "nsw";
    }
    .menu_menuItem__3SSq6:hover {
        grid-gap: 2.62rem;
        gap: 2.62rem;
        transition: all 0.3s;
    }
    .menu_menuItemImg__1_Yt3 {
        width: 3.2rem;
    }
}

.menu_menu__3Lm9e {
    position: fixed;
    left: 0;
    top: -200vh;
    height: 100vh;
    background-color: #fff;
    z-index: 998;
    display: flex;
    flex-direction: column;
    transition: top 0.5s cubic-bezier(0.16, 0, 0.21, 1);
}

html:has(.menu_menu__3Lm9e.menu_menuOpen__r3N6y)::-webkit-scrollbar {
    display: none;
}
html:has(.menu_menu__3Lm9e.menu_menuOpen__r3N6y) {
    overflow: hidden;
}

.menu_menuWrap__3P8Gj {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.menu_menuItem__3SSq6 {
    display: flex;
    align-items: center;
}

.menu_langToggle__1Onw3 {
    margin: 56px 24px;
    color: #9d9d9d !important;
    align-self: flex-end;
}

.langToggle_langToggle__1wO6V {
    font-family: "nsw";
    font-weight: bold;
    color: var(--text-color);
    display: flex;
    font-size: 2rem;
    grid-gap: 1vw;
    gap: 1vw;
}
@media (max-width: 440px) {
    .langToggle_langToggle__1wO6V {
        font-size: 18px;
        grid-gap: 12px;
        gap: 12px;
    }
}
.langToggle_eng__3IpO6 {
    cursor: pointer;
}
.langToggle_kor__2FjNV {
    cursor: pointer;
}

/* Modile */
@media (max-width: 440px) {
    .topbar_topbar___0WgC {
        width: 100vw;
        background-color: #fff;
        padding: 16px;
        grid-gap: 16px;
        gap: 16px;
        align-items: center;
    }

    .topbar_topbar___0WgC * {
        --text-color: #000 !important;
    }

    .topbar_hamburgerBtn__3k_T5 {
        --btn-width: 20px;
        --btn-height: 15px;
        --line-width: 1.6px;
        --gap: 5px;
    }

    .topbar_hamburgerBtn__3k_T5::after,
    .topbar_hamburgerBtn__3k_T5::before {
        transition: transform 0s;
    }

    .topbar_logoWrap__2r3DV div {
        height: 24px;
    }
}

/* Desktop, Tablet */
@media (min-width: 441px) {
    .topbar_topbar___0WgC {
        width: 160rem;
        padding: 3.68rem 3.84rem 0 6rem;
        margin: 0 calc(50% - 80rem);
        grid-gap: 1.5rem;
        gap: 1.5rem;
        position: absolute;
        top: 0;
        align-items: flex-start;
    }
    .topbar_hamburgerBtn__3k_T5 {
        --btn-width: 3.75rem;
        --btn-height: 2.5rem;
        --line-width: 0.5rem;
        --gap: 0.5rem;
    }

    .topbar_hamburgerBtn__3k_T5::after,
    .topbar_hamburgerBtn__3k_T5::before {
        transition: transform 0.3s;
    }

    .topbar_logoWrap__2r3DV div {
        width: 6rem;
    }
}

.topbar_topbar___0WgC {
    display: flex;
    z-index: 999;

    box-sizing: border-box;
}

.topbar_topbar___0WgC.topbar_white__1MDmw {
    --text-color: #fff;
}
.topbar_topbar___0WgC.topbar_gray__3dnwt {
    --text-color: #9d9d9d;
}
.topbar_topbar___0WgC.topbar_black__2M7qb {
    --text-color: #000;
}
.topbar_topbar___0WgC.topbar_fixed__2yy8E {
    position: fixed;
    top: 0;
    left: 0;
}

.topbar_logoWrap__2r3DV {
    margin: 0 auto 0 0;
    justify-self: center;
}

.topbar_hamburgerBtn__3k_T5 {
    width: var(--btn-width);
    height: var(--btn-height);
    position: relative;
    cursor: pointer;
}

.topbar_hamburgerBtn__3k_T5::after {
    content: "";
    display: inline-block;
    width: var(--btn-width);
    border-top: var(--line-width) var(--text-color) solid;
    position: absolute;
    top: calc(var(--line-width) * 2 + var(--gap) * 2);
}

.topbar_hamburgerBtn__3k_T5::before {
    content: "";
    display: inline-block;
    height: var(--gap);
    width: var(--btn-width);
    border: var(--line-width) solid var(--text-color);
    border-right: 0;
    border-left: 0;
    position: absolute;
    top: 0;
}

.topbar_hamburgerBtn__3k_T5.topbar_menuOpen__3-gdc::after {
    transform: rotate(45deg);
    top: calc(var(--btn-height) / 2);
}

.topbar_hamburgerBtn__3k_T5.topbar_menuOpen__3-gdc::before {
    border-top: var(--line-width) var(--text-color) solid;
    border-bottom: 0;
    width: var(--btn-width);
    height: 0;
    position: absolute;
    top: calc(var(--btn-height) / 2);

    transform: rotate(-45deg);
}

/* Mobile */
@media (max-width: 440px) {
    .topbtn_topBtn__2H3G9 {
        width: 36px;
        right: 24px;
        bottom: 28px;
    }
}

/* Desktop */
@media (min-width: 440px) {
    .topbtn_topBtn__2H3G9 {
        width: 3.5rem;
        right: 4rem;
        bottom: 3rem;
    }
}

.topbtn_topBtn__2H3G9 {
    position: fixed;
    cursor: pointer;
}

.topbtn_topBtn__2H3G9 img {
    width: 100%;
}

.about_about__wKKvq {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 17.9vw;
}
.about_logo__dD1VT {
    width: 40rem;
    margin: 23rem 0 7.3rem 0;
}
.about_moto__2uoau {
    font: 3.75rem "nsw";
    margin-bottom: 9.25rem;
    text-align: center;
}
.about_desc__2LQQ5 {
    text-align: center;
    font: lighter 2.5rem "hn";
    line-height: 1.3;
    margin-bottom: 11.58rem;
}
.about_people__23BbI {
    margin-bottom: 11.36rem;
    width: 100rem;
}
@media (max-width: 440px) {
    .about_about__wKKvq {
        padding: 0 16px;
    }
    .about_logo__dD1VT {
        width: 172px;
        margin: 40px 0 48px 0;
    }
    .about_moto__2uoau {
        font: 500 26px "nsw";
        margin-bottom: 40px;
        text-align: center;
    }
    .about_desc__2LQQ5 {
        font: 14px "hn";
        line-height: 1.3;
        margin-bottom: 72px;
    }
    .about_people__23BbI {
        margin: 0 -8px;
        margin-bottom: 80px;
        width: auto;
    }
}

.about_person__20B0A {
    width: 33%;
    padding: 3.2rem 2.56rem;
    box-sizing: border-box;

    display: inline-flex;
    flex-direction: column;
    position: relative;
    grid-gap: 0.64rem;
    gap: 0.64rem;
}
@media (max-width: 440px) {
    .about_person__20B0A {
        width: 50%;
        padding: 16px 8px;
    }
}
.about_person__20B0A.about_selected__E0Qgv {
    color: #006ab7;
}
.about_profileImgWrap__1SXef {
    width: 100%;
    margin-bottom: 1.6rem;
}
@media (max-width: 440px) {
    .about_profileImgWrap__1SXef {
        margin-bottom: 16px;
    }
}
.about_name__PN4zu {
    font: bold 2.9rem "nsw";
}
@media (max-width: 440px) {
    .about_name__PN4zu {
        font-size: 16px;
        margin-bottom: 8px;
    }
}
.about_role__2btKu,
.about_learnMoreBtn__1QgMu {
    font: 1.8rem "hn";
    color: inherit;
}
@media (max-width: 440px) {
    .about_role__2btKu,
    .about_learnMoreBtn__1QgMu {
        font-size: 14px;
        margin-bottom: 8px;
    }
}
.about_learnMoreBtn__1QgMu {
    margin-top: auto;
    cursor: pointer;
}

.about_learnMoreBtn__1QgMu img {
    margin: 0 0 0.2rem 1rem;
    height: 0.8rem;
    transition: all 0.3s;
}
@media (max-width: 440px) {
    .about_learnMoreBtn__1QgMu img {
        height: 7px;
        margin: 0 0 0 20px;
    }
}

.about_person__20B0A.about_selected__E0Qgv .about_learnMoreBtn__1QgMu img {
    transform: rotate(-180deg);
}

.about_person__20B0A:not(.about_selected__E0Qgv) .about_role__2btKu {
    color: #8a8a8a;
}
.about_person__20B0A:not(.about_selected__E0Qgv) .about_learnMoreBtn__1QgMu {
    color: #00aeef;
}

.about_learnMore__3NizS {
    display: block;
    width: 100%;
    box-sizing: border-box;
    float: left;
    max-height: 0px;
    overflow: hidden;
    font: 1.68rem "hn";
    line-height: 2.18rem;
    padding: 0 10.3rem;
    border: 1px solid transparent;
    border-left: 0;
    border-right: 0;
}
@media (max-width: 440px) {
    .about_learnMore__3NizS {
        font: lighter 14px "ms";
        width: calc(100vw - 16px);
        box-sizing: border-box;
    }
}
.about_learnMore__3NizS.about_open__3Zr74 {
    max-height: 1500px;
    padding: 2.8rem 10.3rem;
    border: 1px solid #b2b1ad;
    border-left: 0;
    border-right: 0;
    transition: max-height 0.5s ease;
}
@media (max-width: 440px) {
    .about_learnMore__3NizS.about_open__3Zr74 {
        padding: 32px 8px;
    }
}

.about_learnMore__3NizS.about_close__38DkY {
    transition: none;
}

.about_learnMore__3NizS p {
    margin: 1em 0;
}

p {
    margin: 0 !important;
}

p.desc_bold__1cNqQ {
    font-weight: bold;
}

p.desc_gray__2AHvr {
    color: #8a8a8a;
}

.desc_icon__2lcev {
    width: 0.8vw;
}
@media (max-width: 440px) {
    .desc_icon__2lcev {
        width: 24px;
    }
}

.desc_img__1g9Ny {
    height: 15.2rem;
    width: 100%;
    overflow: scroll hidden;
}
@media (max-width: 440px) {
    .desc_img__1g9Ny {
        height: 140px;
    }
}

.desc_img__1g9Ny > img {
    height: 100%;
    width: auto !important;
}

.business_business__zbK7w {
}

.business_banner__2jXea {
    position: relative;
    width: 160rem;
    margin: 0 calc(50% - 80rem);
}

.business_pageName__1T0au {
    position: absolute;
    top: 50%;
    left: 8rem;
    font: 8rem "nsw";
    color: #fff;
}

.business_contentsWrap__2pYRE {
    margin: 7.81vw 16.79vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 440px) {
    .business_contentsWrap__2pYRE {
        margin: 0;
        padding: 0 16px;
    }
}

.business_content__3R37N {
    margin: 80px 0 !important;
}
@media (min-width: 441px) {
    .business_content__3R37N {
        display: flex;
        align-items: center;
        grid-gap: 6.25vw;
        gap: 6.25vw;
        margin: 8.4vw 0;
    }
    .business_content__3R37N:nth-child(2n) {
        flex-direction: row-reverse;
    }
}

.business_content__3R37N:nth-child(1) .business_title__3mIPX {
    color: #006ab7;
}
.business_content__3R37N:nth-child(2) .business_title__3mIPX {
    color: #7e57c6;
}
.business_content__3R37N:nth-child(3) .business_title__3mIPX {
    color: #fc6c0f;
}
.business_content__3R37N:nth-child(4) .business_title__3mIPX {
    color: #00ae41;
}
.business_content__3R37N:nth-child(5) .business_title__3mIPX {
    color: #1dc1dd;
}

@media (min-width: 441px) {
    .business_content__3R37N > .business_imgWrap__2BAsZ {
        width: 34.54rem;
        height: 34.54rem;
        border-radius: 1000px;
    }
    .business_texts__ykCV5 > .business_imgWrap__2BAsZ {
        display: none;
    }
}

@media (max-width: 440px) {
    .business_content__3R37N > .business_imgWrap__2BAsZ {
        display: none;
    }
    .business_texts__ykCV5 > .business_imgWrap__2BAsZ {
        width: 204px;
        margin-bottom: 32px;
    }
}

.business_texts__ykCV5 {
    width: 58.11rem;
}

@media (max-width: 440px) {
    .business_texts__ykCV5 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.business_title__3mIPX {
    font: 4.68rem "nsw";
    margin-bottom: 2.8vw;
}
@media (max-width: 440px) {
    .business_title__3mIPX {
        text-align: center;
        font-size: 24px;
        margin-bottom: 40px;
    }
}

.business_oneLine__1j4AR {
    font: 2.5rem "hn";
    line-height: 1.3;
    margin-bottom: 2vw;
}

@media (max-width: 440px) {
    .business_oneLine__1j4AR {
        font-size: 16px;
        text-align: center;
    }
}

.business_desc__1PoNs {
    font: 100 2.5rem "hn";
    line-height: 1.3;
}
@media (max-width: 440px) {
    .business_desc__1PoNs {
        font-size: 14px;
    }
}

.brands_brands__ps4l8 {
    margin-bottom: 11.872rem;
}

.brands_banner__1oInG {
    width: 160rem;
    margin: 0 calc(50% - 80rem);
    margin-bottom: 10rem;
}

.brands_desc__1Ua49 {
    text-align: center;
    width: 106rem;
    font: lighter 2.5rem "hn";
    margin: 0 calc(50% - 53rem) !important;
    margin-bottom: 12.64rem !important;
}

@media (max-width: 440px) {
    .brands_desc__1Ua49 {
        font-size: 14px;
        padding: 0 16px;
    }
}

.brandDetail_brands__UTWEu {
    margin-bottom: 11.872rem;
}

.brandDetail_banner__6LQk_ {
    width: 160rem;
    margin: 0 calc(50% - 80rem);
    margin-bottom: 10rem;
}

.brandDetail_brandDetail__2rgN0 {
    /* padding: 0 11vw 5.47vw; */
    width: 123.25rem;
    margin: 0 calc((100% - 123.25rem) / 2);
}

@media (max-width: 440px) {
    .brandDetail_brandDetail__2rgN0 {
        padding: 0 16px 80px 16px;
        width: auto;
        margin: 0;
    }
}
.brandDetail_pageName__1vlCH {
    font: lighter 1.8rem "hn";
    color: #636363;
}
@media (max-width: 440px) {
    .brandDetail_pageName__1vlCH {
        font-size: 12px;
        margin-bottom: 20px;
    }
}
.brandDetail_brandName__3vc9m {
    font: 3.75rem "nsw";
    margin-bottom: 4rem;
}
@media (max-width: 440px) {
    .brandDetail_brandName__3vc9m {
        font: 500 24px "nsw";
        margin-bottom: 20px;
    }
}
.brandDetail_section1__1Gp4L {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0 8.96rem;
    gap: 0 8.96rem;
    margin-bottom: 12.5rem;
}
@media (max-width: 440px) {
    .brandDetail_section1__1Gp4L {
        grid-gap: 20px;
        gap: 20px;
        margin-bottom: 32px;
    }
}
.brandDetail_desc__2Zk17 {
    font: lighter 2.31rem "hn";
    flex: 1 1;
}
@media (max-width: 440px) {
    .brandDetail_desc__2Zk17 {
        font-size: 16px;
        line-height: 22px;
    }
}
.brandDetail_table__3Z7Nn {
    flex: 1.2 1;
}
.brandDetail_row__1CTpP {
    color: #636363;
    border-bottom: 1px solid #0971ce;
}
.brandDetail_rowName__2Ei97 {
    font: 1.8rem "nsw";
    padding-right: 128px;
    padding: 1.2rem 16px;
    vertical-align: text-top;
}
.brandDetail_rowValue__3Vz1T {
    font: lighter 1.8rem "hn";
    padding: 1.2rem 16px;
    vertical-align: text-top;
}
@media (max-width: 440px) {
    .brandDetail_rowName__2Ei97,
    .brandDetail_rowValue__3Vz1T {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
@media screen and (max-width: 1280px) {
    .brandDetail_desc__2Zk17,
    .brandDetail_table__3Z7Nn {
        min-width: 0%;
    }
}

.brandDetail_sns__3RW_3 {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    align-items: center;
    padding: 0.8rem;
}

.brandDetail_snsImg__epY9c {
    width: 1.6rem;
}
@media (max-width: 440px) {
    .brandDetail_snsImg__epY9c {
        width: 24px;
    }
}

@media (max-width: 440px) {
    .brandDetail_sns__3RW_3 {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        grid-gap: 9px;
        gap: 9px;
        align-items: center;
        padding: 0;
    }

    .brandDetail_desc__2Zk17,
    .brandDetail_table__3Z7Nn {
        min-width: 100%;
    }
}

.brandDetail_imgs__2LTEI {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2.4rem;
    gap: 2.4rem;
    margin-bottom: 12.8rem;
}

.brandDetail_imgs__2LTEI .brandDetail_imgWrap__apd3v.brandDetail_large__GvuWw {
    width: calc((100% - 2.4rem) / 2);
}

.brandDetail_imgs__2LTEI .brandDetail_imgWrap__apd3v.brandDetail_small__2puYM {
    width: calc((100% - 4.8rem) / 3);
}

@media (max-width: 440px) {
    .brandDetail_imgs__2LTEI {
        grid-gap: 2.4rem;
        gap: 2.4rem;
    }

    .brandDetail_imgs__2LTEI .brandDetail_imgWrap__apd3v.brandDetail_large__GvuWw {
        width: 100%;
    }

    .brandDetail_imgs__2LTEI .brandDetail_imgWrap__apd3v.brandDetail_small__2puYM {
        width: 100%;
    }
}

.brandDetail_section2__1gB-E {
    display: flex;
}
@media (max-width: 440px) {
    .brandDetail_section2__1gB-E {
        display: block;
    }
}

.brandDetail_sectionName__1I6Nw {
    font: 3.75rem "nsw";
    margin-bottom: 4rem;
}
@media (max-width: 440px) {
    .brandDetail_sectionName__1I6Nw {
        font-size: 24px;
        margin-bottom: 24px;
    }
}

.brandDetail_partner__-ficH {
    flex: 1 1;
}
.brandDetail_partner__-ficH .brandDetail_imgWrap__apd3v {
    width: 25.6rem;
}
@media (max-width: 440px) {
    .brandDetail_partner__-ficH .brandDetail_imgWrap__apd3v {
        height: 120px;
        width: auto;
    }
    .brandDetail_partner__-ficH .brandDetail_imgWrap__apd3v img {
        width: auto !important;
        height: 100%;
    }
}

.brandDetail_relatedNews__3DKZa {
    flex: 1 1;
}
.brandDetail_newsThumbnails__2iXat {
    display: flex;
    grid-gap: 2.88rem;
    gap: 2.88rem;
}
@media (max-width: 440px) {
    .brandDetail_newsThumbnails__2iXat {
        flex-direction: column;
    }
}

.news_news__zJ3Kj {
    padding: 23rem 0 12rem 0;
    width: 123.25rem;
    margin: 0 calc(50% - 61.62rem);
    text-align: center;
}
@media (max-width: 440px) {
    .news_news__zJ3Kj {
        width: auto;
        margin: 0;
        padding: 56px 16px;
    }
}
.news_title__3r1Ft {
    margin-bottom: 23rem;
}

.news_newsWrap__REur0 {
    display: flex;
    grid-gap: 9.12rem 2.3rem;
    gap: 9.12rem 2.3rem;
    flex-wrap: wrap;
    text-align: left;
}

@media (max-width: 440px) {
    .news_newsWrap__REur0 {
        grid-gap: 48px;
        gap: 48px;
    }
}


.news_loadMoreBtn__36zhr {
    display: inline-block;
    font: bold 2rem "nsw";
    background-color: #006ab7;
    color: #fff;
    border-radius: 5px;
    padding: 1rem 2rem;
    cursor: pointer;
    margin-top: 8rem;
}

@media (max-width: 440px) {
    .news_loadMoreBtn__36zhr {
        font: bold 18px "nsw";
        padding: 8px 24px;
        margin-top: 48px;
    }
}
.newsDetail_newsDetailWrap__p737k {
    text-align: center;
    padding: 23rem 0 11.8rem 0;
    width: 105.62rem;
    margin: 0 calc(50% - 52.81rem);
}
@media (max-width: 440px) {
    .newsDetail_newsDetailWrap__p737k {
        width: auto;
        margin: 0;
        text-align: left;
        padding: 56px 16px;
    }
}

.newsDetail_newsDetailPageName__OdG92 {
    display: none;
}
@media (max-width: 440px) {
    .newsDetail_newsDetailPageName__OdG92 {
        display: block;
        font: lighter 12px "hn";
        color: #636363;
        margin-bottom: 24px;
    }
}

.newsDetail_newsTitle__X5Py5 {
    font: 4.37rem "hn", "ms";
    line-height: 1.28;
    margin-bottom: 3.2rem;
}
@media (max-width: 440px) {
    .newsDetail_newsTitle__X5Py5 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
    }
}

.newsDetail_newsDate__9-RGF {
    font: 2.18rem "hn", "ms";
    color: #636363;
    margin-bottom: 6.4rem;
}
@media (max-width: 440px) {
    .newsDetail_newsDate__9-RGF {
        font-size: 12px;
        margin-bottom: 32px;
    }
}

.newsDetail_newsImg__2Gcdo {
    margin-bottom: 1.6rem;
}

.newsDetail_newsContent__1CRda {
    text-align: left;
    font: lighter 2.18rem "hn", "ms";
}
@media (max-width: 440px) {
    .newsDetail_newsContent__1CRda {
        font-size: 14px;
    }
}

.newsDetail_newsContent__1CRda * {
    margin-bottom: 1em;
    font-size: inherit;
}

.postBlock_Heading__23kNE {
    font: 4.37rem "hn", "ms";
    line-height: 1.28;
    margin-bottom: 3.2rem;
}

.postBlock_Heading2__msS5J {
    font: 3.5rem "hn", "ms";
    line-height: 1.28;
    margin-bottom: 2.56rem;
}

.postBlock_Paragraph__2khGU {
    text-align: left;
    font: lighter 2.18rem "hn", "ms";
    line-height: 1.5;
    margin: 1.09rem 0 !important;
    min-height: 2.18rem;
}

.postBlock_ImgCaption__2K3uz {
    font: lighter 2.18rem "hn", "ms";
    color: #9d9d9d;
    line-height: 1.85;
    margin-bottom: 9.28rem;
}

.postBlock_Img__159p2 {
}

.postBlock_List__2Yllj {
}

.postBlock_Table__2fiNg {
}

.postBlock_Hr__2De3V {
}

@media (max-width: 440px) {
    .postBlock_Heading__23kNE {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .postBlock_Paragraph__2khGU {
        font-size: 14px;
        margin: 7px 0 !important;
    }

    .postBlock_ImgCaption__2K3uz {
        font-size: 12px;
        word-break: keep-all;
        text-align: center;
    }

    .postBlock_Img__159p2 {
        width: 100%;
    }

    .postBlock_List__2Yllj {
    }

    .postBlock_Table__2fiNg {
    }

    .postBlock_Hr__2De3V {
    }
}

.post_post__3f7Gi {
    display: flex;
    flex-direction: column;
}

.careers_careers__1_faX {
    width: 106.25rem;
    padding: 23rem calc(50% - 53.1rem) 20rem calc(50% - 53.1rem);
    font-family: "hn", "ms";
}

.careers_pageTitle__3KWSu {
    margin-bottom: 23rem;
}

.careers_postNumber__3EPrs {
    font: 2.5rem "hn", "ms";
}

.careers_blue__1iIKg {
    color: #006ab7;
}

.careers_post__3w6mz {
    display: block;
    padding: 4.64rem;
    border-bottom: 1px #bebebe solid;
}

.careers_upperSubtitle__2SsqU {
    font: bold 1.8rem "hn", "ms";
    color: #8a8a8a;
    margin-bottom: 2.88rem;
}

.careers_postTitle__2BSx4 {
    font: bold 3.43rem "hn", "ms";
    margin-bottom: 2.88rem;
}

.careers_underSubtitle__3-2bG {
    font: bold 2.1rem "hn", "ms";
    color: #8a8a8a;
}

@media (max-width: 440px) {
    .careers_careers__1_faX {
        padding: 56px 16px 80px 16px;
        width: calc(100vw - 32px);
    }

    .careers_pageTitle__3KWSu {
        margin-bottom: 56px;
        font-size: 40px;
    }

    .careers_postNumber__3EPrs {
        font-size: 14px;
    }

    .careers_blue__1iIKg {
        color: #006ab7;
    }

    .careers_post__3w6mz {
        display: block;
        padding: 40px 0;
        border-bottom: 1px #bebebe solid;
    }

    .careers_upperSubtitle__2SsqU {
        font: 12px "ms";
        color: #8a8a8a;
        margin-bottom: 16px;
    }

    .careers_postTitle__2BSx4 {
        font: bold 16px "ms";
        margin-bottom: 16px;
    }

    .careers_underSubtitle__3-2bG {
        font: bold 14px "ms";
        color: #8a8a8a;
    }
}

:root {
    --map-mobile-height: calc((100vw - 32px) * 0.75);
}

.map_mapWrapper__2-OyS {
    flex: 1 1;
    height: auto;
}

.map_mapShadow__i1VtE {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));
}
@media (max-width: 440px) {
    .map_mapShadow__i1VtE {
        filter: none;
    }
}
.map_rawMap__3Yze3 {
    width: 100%;
    height: 41.5rem;
}
@media (max-width: 440px) {
    .map_rawMap__3Yze3 {
        height: var(--map-mobile-height);
    }
}
.map_mapLoading__2pedx {
    width: 100%;
    height: 41.5rem;
    font: lighter 2rem "hn";
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9d9d9;
    text-align: center;
}
@media (max-width: 440px) {
    .map_mapLoading__2pedx {
        height: var(--map-mobile-height);
    }
}
.map_mapText__1N5nI {
    margin-top: 2.26rem;
}
@media (max-width: 440px) {
    .map_mapText__1N5nI {
        margin-top: 32px;
    }
}
.map_locationTitle__2-EPD {
    font: 3.75rem "nsw";
    display: flex;
    align-items: center;
}
@media (max-width: 440px) {
    .map_locationTitle__2-EPD {
        font-size: 20px;
    }
}

.map_locationTitle__2-EPD > img {
    margin-right: 1.36rem;
    width: 2.3rem;
}
@media (max-width: 440px) {
    .map_locationTitle__2-EPD > img {
        width: 15px;
        margin-right: 15px;
    }
}

.map_locationText__3Csne {
    font: 2.5rem "hn";
    color: #8a8a8a;
    margin: 1.87rem 0 0 3.66rem;
}
@media (max-width: 440px) {
    .map_locationText__3Csne {
        font-size: 14px;
        margin: 16px 0 0 30px;
    }
}

.contact_pageTitle__upOwF {
    margin: 0;
    height: 52.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact_contantWrap__nJbkU {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 9vw 0;
    width: 105.5rem;
    margin: 0 calc(50% - 52.27rem);
    justify-content: center;
    grid-gap: 15.84rem;
    gap: 15.84rem;
}
@media (max-width: 440px) {
    .contact_contantWrap__nJbkU {
        width: auto;
        margin: 0;
        flex-direction: column;
        padding: 0 16px 80px 16px;
        grid-gap: 56px;
        gap: 56px;
    }
}
.contact_emailTitle__3yaxd > img {
    width: 2.56rem;
    margin-right: 1.4rem;
}
@media (max-width: 440px) {
    .contact_emailTitle__3yaxd > img {
        width: 20px;
        margin-right: 12px;
    }
}

.contact_emailTitle__3yaxd {
    font: 3.75rem "nsw";
    display: flex;
    align-items: center;
    margin-bottom: 4.5rem;
}
@media (max-width: 440px) {
    .contact_emailTitle__3yaxd {
        font-size: 20px;
        margin-bottom: 32px;
    }
}
.contact_emailWrap__1hA4J {
    flex: 1 1;
}
.contact_email__3M_u2 {
    margin-left: 4rem;
}
@media (max-width: 440px) {
    .contact_email__3M_u2 {
        margin-left: 32px;
    }
}

.contact_email__3M_u2:not(.contact_email__3M_u2:last-child) {
    margin-bottom: 3.84rem;
}
@media (max-width: 440px) {
    .contact_email__3M_u2:not(.contact_email__3M_u2:last-child) {
        margin-bottom: 32px;
    }
}

.contact_email__3M_u2 > .contact_propose__1dIXn {
    font: 3.12rem "nsw";
}
@media (max-width: 440px) {
    .contact_email__3M_u2 > .contact_propose__1dIXn {
        font-size: 16px;
    }
}

.contact_email__3M_u2 > .contact_emailAdress__1gooG {
    font: 2.18rem "hn";
    color: #8a8a8a;
    margin-top: 1rem;
}
@media (max-width: 440px) {
    .contact_email__3M_u2 > .contact_emailAdress__1gooG {
        font-size: 14px;
        margin-top: 16px;
    }
}

.careerPost_careerPost__2k5-O {
    width: 123.5rem;
    padding: 25.6rem calc(50% - 61.75rem);
}

.careerPost_careerPost__2k5-O > * {
    font: 1.87rem "hn", "ms";
    line-height: 1.8;
    color: #8a8a8a;
}

.careerPost_title__e3X57 {
    font: bold 4.5rem "hn", "ms";
    margin-bottom: 1.44rem;
    color: #000;
}

.careerPost_subtitle__hENmh {
    font: 2.18rem "hn", "ms";
    color: #8a8a8a;
    margin-bottom: 2.88rem;
}

.careerPost_sectionTitle__3DmP_::before {
    content: "";
    display: inline-block;
    width: 0.25rem;
    height: 2.81rem;
    background-color: #006ab7;
    margin-right: 1.12rem;
}
.careerPost_sectionTitle__3DmP_ {
    font: bold 3.31rem "hn", "ms";
    color: #006ab7;
    height: auto;
    display: flex;
    align-items: center;
    margin: 0 0 1.6rem 0;
}

.careerPost_sectionSubTitle__Lkxqd {
    font: bold 2.25rem "hn", "ms";
    color: #006ab7;
    height: auto;
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;
}

.careerPost_paragraph__iqbgI {
    font: 1.87rem "hn", "ms" !important;
    margin: 0 !important;
    line-height: 1.8 !important;
    min-height: 3.36rem;
}

.careerPost_table__3Sqym {
    border-collapse: separate;
    border-spacing: 0 16px;
}

.careerPost_table__3Sqym td {
    font: 1.87rem "hn", "ms";
    line-height: 1.8;
    color: #8a8a8a;
}

.careerPost_table__3Sqym td:first-child {
    font: bold 2.25rem "hn", "ms";
    padding: 0 1.25rem 0 0;
    color: #006ab7;
    white-space: nowrap;
    width: 1%;
}

@media (max-width: 440px) {
    .careerPost_careerPost__2k5-O {
        padding: 40px 16px 80px 16px;
        width: calc(100vw - 32px);
    }

    .careerPost_careerPost__2k5-O > * {
        font: 14px "hn", "ms";
        line-height: 1.8;
    }

    .careerPost_title__e3X57 {
        font: bold 16px "hn", "ms";
        margin-bottom: 18px;
    }

    .careerPost_subtitle__hENmh {
        font: 14px "hn", "ms";
        margin-bottom: 24px;
    }

    .careerPost_sectionTitle__3DmP_::before {
        width: 2px;
        height: 14px;
        margin-right: 8px;
    }

    .careerPost_sectionTitle__3DmP_ {
        font: bold 16px "hn", "ms";
        height: auto;
        display: flex;
        align-items: center;
        margin: 0 0 24px 0;
    }

    .careerPost_sectionSubTitle__Lkxqd {
        font: normal 16px "hn", "ms";
        color: #006ab7;
        height: auto;
        display: flex;
        align-items: center;
        margin: 0 0 12px 0;
    }

    .careerPost_paragraph__iqbgI {
        font-size: 14px !important;
        min-height: 25.2px;
    }

    .careerPost_table__3Sqym {
        border-collapse: separate;
        border-spacing: 0 6px;
    }

    .careerPost_table__3Sqym td {
        font: 14px "hn", "ms";
    }

    .careerPost_table__3Sqym td:first-child {
        font: normal 16px "hn", "ms";
        padding: 0 12px 0 0;
    }
}

