.wrap {
    text-align: center;
    display: flex;
    justify-content: center;
}

@media (width > 440px) {
    .brandList {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 106.7rem;
        gap: 5rem;
    }

    .imgWrap {
        display: flex;
        position: relative;
        height: 100%;
    }
    .item {
        width: 100%;
        height: 0;
        padding-bottom: 111.54%;
        overflow: hidden;
        position: relative;
    }

    .imgWrap {
        display: flex;
        position: relative;
        height: 100%;
    }

    .imgWrap > img {
        position: absolute;
        top: 0;
        left: 0;
    }

    .link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .item:hover img {
        position: absolute;
        top: -5%;
        left: -5%;
        width: 110% !important;
        transition: all 0.3s;
    }

    .link {
        background-color: #006ab7;
        opacity: 0.7;
        color: #fff;
        font: bold 2.5rem "nsw";
        line-height: 1.2;
        display: flex;
        padding: 0.7vw;
        box-sizing: border-box;
        transition: all 0.3s;
    }

    .link * {
        margin: auto;
    }

    .item:not(:hover) .link {
        opacity: 0;
    }
}

@media (width <= 440px) {
    .wrap {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 16px;
    }
    .brandList {
        display: grid;
        width: 100%;
        gap: 32px 16px;
        grid-template-columns: 1fr 1fr;
    }
    .item {
        position: relative;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .link {
        display: none;
    }
    .brandName {
        font: 500 14px "nsw";
        text-align: left;
    }
}
