.home > *:first-child {
    margin-bottom: 15.75rem;
}

.home > * {
    margin-bottom: 20rem;
}

.intro {
    text-align: center;
    padding: 0 48px;
}
@media (width <= 440px) {
    .intro {
        text-align: center;
        padding: 0;
    }
}

.moto {
    font: 3rem "nsw";
    margin-bottom: 0.93vw;
}
@media (width <= 440px) {
    .moto {
        font: bold 15px "nsw";
        padding: 0 16px;
    }
}

.companyName {
    font: bold 4.5rem "nsw";
    margin-bottom: 5rem;
}
@media (width <= 440px) {
    .companyName {
        font: bold 24px "nsw";
        margin-bottom: 40px;
    }
}

.desc1,
.desc2,
.desc3 {
    font: lighter 3rem "hn";
    margin-bottom: 1rem;
}
@media (width <= 440px) {
    .desc1,
    .desc2,
    .desc3 {
        font: 14px "hn";
        line-height: 20px;
        padding: 0 24px;
    }
}

.businessImgWrap {
    width: 124.63rem;
}
@media (width <= 440px) {
    .businessImgWrap {
        width: auto;
        height: 160px;
        overflow: scroll hidden;
    }
    .businessImgWrap img {
        margin: 0 16px;
        height: 100%;
        width: auto !important;
    }
}

.newsWrap {
    display: flex;
    justify-content: center;
    gap: 4.63rem;
    width: 100vw;
    display: flex;
    justify-content: center;
}

@media (width <= 440px) {
    .newsWrap {
        padding: 16px;
        flex-direction: column;
        box-sizing: border-box;
    }
}
