.business {
}

.banner {
    position: relative;
    width: 160rem;
    margin: 0 calc(50% - 80rem);
}

.pageName {
    position: absolute;
    top: 50%;
    left: 8rem;
    font: 8rem "nsw";
    color: #fff;
}

.contentsWrap {
    margin: 7.81vw 16.79vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (width <= 440px) {
    .contentsWrap {
        margin: 0;
        padding: 0 16px;
    }
}

.content {
    margin: 80px 0 !important;
}
@media (width > 440px) {
    .content {
        display: flex;
        align-items: center;
        gap: 6.25vw;
        margin: 8.4vw 0;
    }
    .content:nth-child(2n) {
        flex-direction: row-reverse;
    }
}

.content:nth-child(1) .title {
    color: #006ab7;
}
.content:nth-child(2) .title {
    color: #7e57c6;
}
.content:nth-child(3) .title {
    color: #fc6c0f;
}
.content:nth-child(4) .title {
    color: #00ae41;
}
.content:nth-child(5) .title {
    color: #1dc1dd;
}

@media (width > 440px) {
    .content > .imgWrap {
        width: 34.54rem;
        height: 34.54rem;
        border-radius: 1000px;
    }
    .texts > .imgWrap {
        display: none;
    }
}

@media (width <= 440px) {
    .content > .imgWrap {
        display: none;
    }
    .texts > .imgWrap {
        width: 204px;
        margin-bottom: 32px;
    }
}

.texts {
    width: 58.11rem;
}

@media (width <= 440px) {
    .texts {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.title {
    font: 4.68rem "nsw";
    margin-bottom: 2.8vw;
}
@media (width <= 440px) {
    .title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 40px;
    }
}

.oneLine {
    font: 2.5rem "hn";
    line-height: 1.3;
    margin-bottom: 2vw;
}

@media (width <= 440px) {
    .oneLine {
        font-size: 16px;
        text-align: center;
    }
}

.desc {
    font: 100 2.5rem "hn";
    line-height: 1.3;
}
@media (width <= 440px) {
    .desc {
        font-size: 14px;
    }
}
