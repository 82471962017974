.title {
    font: 10.3rem "nsb";
    text-align: center;
    margin-bottom: 9.68rem;
}

@media (width <= 440px) {
    .title {
        font: 40px "nsb";
        text-align: center;
        margin-bottom: 40px;
    }
}
