.brands {
    margin-bottom: 11.872rem;
}

.banner {
    width: 160rem;
    margin: 0 calc(50% - 80rem);
    margin-bottom: 10rem;
}

.brandDetail {
    /* padding: 0 11vw 5.47vw; */
    width: 123.25rem;
    margin: 0 calc((100% - 123.25rem) / 2);
}

@media (width <= 440px) {
    .brandDetail {
        padding: 0 16px 80px 16px;
        width: auto;
        margin: 0;
    }
}
.pageName {
    font: lighter 1.8rem "hn";
    color: #636363;
}
@media (width <= 440px) {
    .pageName {
        font-size: 12px;
        margin-bottom: 20px;
    }
}
.brandName {
    font: 3.75rem "nsw";
    margin-bottom: 4rem;
}
@media (width <= 440px) {
    .brandName {
        font: 500 24px "nsw";
        margin-bottom: 20px;
    }
}
.section1 {
    display: flex;
    flex-wrap: wrap;
    gap: 0 8.96rem;
    margin-bottom: 12.5rem;
}
@media (width <= 440px) {
    .section1 {
        gap: 20px;
        margin-bottom: 32px;
    }
}
.desc {
    font: lighter 2.31rem "hn";
    flex: 1;
}
@media (width <= 440px) {
    .desc {
        font-size: 16px;
        line-height: 22px;
    }
}
.table {
    flex: 1.2;
}
.row {
    color: #636363;
    border-bottom: 1px solid #0971ce;
}
.rowName {
    font: 1.8rem "nsw";
    padding-right: 128px;
    padding: 1.2rem 16px;
    vertical-align: text-top;
}
.rowValue {
    font: lighter 1.8rem "hn";
    padding: 1.2rem 16px;
    vertical-align: text-top;
}
@media (width <= 440px) {
    .rowName,
    .rowValue {
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
@media screen and (width <= 1280px) {
    .desc,
    .table {
        min-width: 0%;
    }
}

.sns {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 0.8rem;
    align-items: center;
    padding: 0.8rem;
}

.snsImg {
    width: 1.6rem;
}
@media (width <= 440px) {
    .snsImg {
        width: 24px;
    }
}

@media (width <= 440px) {
    .sns {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        gap: 9px;
        align-items: center;
        padding: 0;
    }

    .desc,
    .table {
        min-width: 100%;
    }
}

.imgs {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
    margin-bottom: 12.8rem;
}

.imgs .imgWrap.large {
    width: calc((100% - 2.4rem) / 2);
}

.imgs .imgWrap.small {
    width: calc((100% - 4.8rem) / 3);
}

@media (width <= 440px) {
    .imgs {
        gap: 2.4rem;
    }

    .imgs .imgWrap.large {
        width: 100%;
    }

    .imgs .imgWrap.small {
        width: 100%;
    }
}

.section2 {
    display: flex;
}
@media (width <= 440px) {
    .section2 {
        display: block;
    }
}

.sectionName {
    font: 3.75rem "nsw";
    margin-bottom: 4rem;
}
@media (width <= 440px) {
    .sectionName {
        font-size: 24px;
        margin-bottom: 24px;
    }
}

.partner {
    flex: 1;
}
.partner .imgWrap {
    width: 25.6rem;
}
@media (width <= 440px) {
    .partner .imgWrap {
        height: 120px;
        width: auto;
    }
    .partner .imgWrap img {
        width: auto !important;
        height: 100%;
    }
}

.relatedNews {
    flex: 1;
}
.newsThumbnails {
    display: flex;
    gap: 2.88rem;
}
@media (width <= 440px) {
    .newsThumbnails {
        flex-direction: column;
    }
}
