@media (width <= 440px) {
    .footer {
        background-color: #3c3c3c;
        color: #fff;
        display: flex;
        flex-direction: column;
        gap: 40px;

        padding: 32px 16px;

        font: lighter 14px "hn";
    }
    .menuList {
        margin-bottom: 40px;
    }
    .menuItem {
        padding: 14.5px 0;
        border-bottom: 0.5px solid rgb(255, 255, 255);
    }
    .dropDownBtn {
        float: right;
        margin-right: 8px;
        transition: transform 0.5s;
    }
    .dropDownBtn.open {
        transform: rotateX(180deg);
    }
    .brandDetailDropdown {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        gap: 8px;
        padding: 0 0 0 24px;
    }
    .brandDetailDropdown.open {
        padding: 8px 0 0 24px;
    }
    .policyItem {
        display: inline-block;
        width: 50%;
    }
    .sns {
        display: flex;
        gap: 8px;
        align-items: center;
    }
    .sns img {
        width: 24px;
    }
    .companyInfo {
        font: lighter 12px "ms";
        margin-top: 40px;
    }
    .copyright {
        margin-top: 40px;
    }
}

@media (width >= 440px) {
    .footer {
        background-color: #3c3c3c;
        color: #fff;

        font: lighter 1.88rem "hn";

        --menu-column: 4;
        --policy-column: 1;
        --total-column: 5;
    }
    .section1 {
        padding: 5rem 0;
        --width: calc(var(--total-column) * 18rem);
        width: var(--width);
        margin: 0 calc((100% - var(--width)) / 2);
    }
    .menuList {
        width: calc(var(--menu-column) / var(--total-column) * 100%);
        grid-auto-flow: column;

        display: inline-grid;
        grid-template-columns: repeat(var(--menu-column), 1fr);
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: column;
        gap: 3.57rem 0;
    }
    .policies {
        width: calc(var(--policy-column) / var(--total-column) * 100%);

        display: inline-grid;
        grid-template-columns: repeat(var(--policy-column), 1fr);
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: column;
        gap: 3.57rem 0;
    }
    .Hr {
        height: 0.06rem;
        background-color: #d9d9d9;
    }
    .section2 {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 4.16rem 0;
        width: 108.75rem;
        margin: 0 calc((100% - 108.75rem) / 2);
        font: lighter 1.5rem "hn";
    }
    .address,
    .representative {
        display: inline-block;
        margin-bottom: 1em;
    }
    .address {
        margin-right: 5rem;
    }
    .copyright {
    }
    .sns {
        display: flex;
        gap: 0.96rem;
        align-items: center;
    }
    .sns img {
        width: 2.5rem;
    }
}

.youtube,
.instagram {
    /* display: none; */
}
