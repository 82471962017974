.wrap {
    position: relative;
    overflow: hidden;
    width: 160rem;
    margin: 0 calc(50% - 80rem);
    height: 62.4rem;
}

.btn {
    position: absolute;
    top: 0;

    height: 100%;
    display: flex;
    align-items: center;

    cursor: pointer;
    z-index: 10;
}

@media (width <= 440px) {
    .btn {
        display: none;
    }
}

.btnIcon {
    width: 3.56rem;
    height: 4.19rem;
}

.btnIcon:hover {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.nextBtn {
    right: 5.87rem;
}

.prevBtn {
    left: 5.87rem;
}

.slide {
    position: absolute;
    top: -10000px;

    height: 100%;
    width: 100%;

    transition: left 0.2s;
}

.slide.next {
    top: 0;
    left: 100vw;
}

.slide.curr {
    top: 0px;
    left: 0;
    z-index: 9;
}

.slide.prev {
    top: 0;
    left: -100vw;
}

.indicatorWrap {
    position: absolute;
    bottom: 0;
    z-index: 11;
    width: 100%;
    text-align: center;
}

@media (width <= 440px) {
    .indicatorWrap {
        display: none;
    }
}

.indicator {
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
    border: 0.25rem solid #9d9d9d;
    display: inline-block;
    margin: 0.25rem 0.25rem 4.5rem 0.25rem;
    cursor: pointer;
}

.indicator.selected {
    background-color: #9d9d9d;
}

.pageNumber {
    display: none;
}

@media (width <= 440px) {
    .pageNumber {
        display: block;

        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;

        width: 48px;
        height: 20px;

        margin: 15px;

        background: rgba(0, 0, 0, 0.3);
        border-radius: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        font: 14px "nsw";
        color: #fff;
    }
}
