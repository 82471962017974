.pageTitle {
    margin: 0;
    height: 52.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contantWrap {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 9vw 0;
    width: 105.5rem;
    margin: 0 calc(50% - 52.27rem);
    justify-content: center;
    gap: 15.84rem;
}
@media (width <= 440px) {
    .contantWrap {
        width: auto;
        margin: 0;
        flex-direction: column;
        padding: 0 16px 80px 16px;
        gap: 56px;
    }
}
.emailTitle > img {
    width: 2.56rem;
    margin-right: 1.4rem;
}
@media (width <= 440px) {
    .emailTitle > img {
        width: 20px;
        margin-right: 12px;
    }
}

.emailTitle {
    font: 3.75rem "nsw";
    display: flex;
    align-items: center;
    margin-bottom: 4.5rem;
}
@media (width <= 440px) {
    .emailTitle {
        font-size: 20px;
        margin-bottom: 32px;
    }
}
.emailWrap {
    flex: 1;
}
.email {
    margin-left: 4rem;
}
@media (width <= 440px) {
    .email {
        margin-left: 32px;
    }
}

.email:not(.email:last-child) {
    margin-bottom: 3.84rem;
}
@media (width <= 440px) {
    .email:not(.email:last-child) {
        margin-bottom: 32px;
    }
}

.email > .propose {
    font: 3.12rem "nsw";
}
@media (width <= 440px) {
    .email > .propose {
        font-size: 16px;
    }
}

.email > .emailAdress {
    font: 2.18rem "hn";
    color: #8a8a8a;
    margin-top: 1rem;
}
@media (width <= 440px) {
    .email > .emailAdress {
        font-size: 14px;
        margin-top: 16px;
    }
}
