p {
    margin: 0 !important;
}

p.bold {
    font-weight: bold;
}

p.gray {
    color: #8a8a8a;
}

.icon {
    width: 0.8vw;
}
@media (width <= 440px) {
    .icon {
        width: 24px;
    }
}

.img {
    height: 15.2rem;
    width: 100%;
    overflow: scroll hidden;
}
@media (width <= 440px) {
    .img {
        height: 140px;
    }
}

.img > img {
    height: 100%;
    width: auto !important;
}
