.newsDetailWrap {
    text-align: center;
    padding: 23rem 0 11.8rem 0;
    width: 105.62rem;
    margin: 0 calc(50% - 52.81rem);
}
@media (width <= 440px) {
    .newsDetailWrap {
        width: auto;
        margin: 0;
        text-align: left;
        padding: 56px 16px;
    }
}

.newsDetailPageName {
    display: none;
}
@media (width <= 440px) {
    .newsDetailPageName {
        display: block;
        font: lighter 12px "hn";
        color: #636363;
        margin-bottom: 24px;
    }
}

.newsTitle {
    font: 4.37rem "hn", "ms";
    line-height: 1.28;
    margin-bottom: 3.2rem;
}
@media (width <= 440px) {
    .newsTitle {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
    }
}

.newsDate {
    font: 2.18rem "hn", "ms";
    color: #636363;
    margin-bottom: 6.4rem;
}
@media (width <= 440px) {
    .newsDate {
        font-size: 12px;
        margin-bottom: 32px;
    }
}

.newsImg {
    margin-bottom: 1.6rem;
}

.newsContent {
    text-align: left;
    font: lighter 2.18rem "hn", "ms";
}
@media (width <= 440px) {
    .newsContent {
        font-size: 14px;
    }
}

.newsContent * {
    margin-bottom: 1em;
    font-size: inherit;
}
