.careers {
    width: 106.25rem;
    padding: 23rem calc(50% - 53.1rem) 20rem calc(50% - 53.1rem);
    font-family: "hn", "ms";
}

.pageTitle {
    margin-bottom: 23rem;
}

.postNumber {
    font: 2.5rem "hn", "ms";
}

.blue {
    color: #006ab7;
}

.post {
    display: block;
    padding: 4.64rem;
    border-bottom: 1px #bebebe solid;
}

.upperSubtitle {
    font: bold 1.8rem "hn", "ms";
    color: #8a8a8a;
    margin-bottom: 2.88rem;
}

.postTitle {
    font: bold 3.43rem "hn", "ms";
    margin-bottom: 2.88rem;
}

.underSubtitle {
    font: bold 2.1rem "hn", "ms";
    color: #8a8a8a;
}

@media (width <= 440px) {
    .careers {
        padding: 56px 16px 80px 16px;
        width: calc(100vw - 32px);
    }

    .pageTitle {
        margin-bottom: 56px;
        font-size: 40px;
    }

    .postNumber {
        font-size: 14px;
    }

    .blue {
        color: #006ab7;
    }

    .post {
        display: block;
        padding: 40px 0;
        border-bottom: 1px #bebebe solid;
    }

    .upperSubtitle {
        font: 12px "ms";
        color: #8a8a8a;
        margin-bottom: 16px;
    }

    .postTitle {
        font: bold 16px "ms";
        margin-bottom: 16px;
    }

    .underSubtitle {
        font: bold 14px "ms";
        color: #8a8a8a;
    }
}
