.careerPost {
    width: 123.5rem;
    padding: 25.6rem calc(50% - 61.75rem);
}

.careerPost > * {
    font: 1.87rem "hn", "ms";
    line-height: 1.8;
    color: #8a8a8a;
}

.title {
    font: bold 4.5rem "hn", "ms";
    margin-bottom: 1.44rem;
    color: #000;
}

.subtitle {
    font: 2.18rem "hn", "ms";
    color: #8a8a8a;
    margin-bottom: 2.88rem;
}

.sectionTitle::before {
    content: "";
    display: inline-block;
    width: 0.25rem;
    height: 2.81rem;
    background-color: #006ab7;
    margin-right: 1.12rem;
}
.sectionTitle {
    font: bold 3.31rem "hn", "ms";
    color: #006ab7;
    height: auto;
    display: flex;
    align-items: center;
    margin: 0 0 1.6rem 0;
}

.sectionSubTitle {
    font: bold 2.25rem "hn", "ms";
    color: #006ab7;
    height: auto;
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;
}

.paragraph {
    font: 1.87rem "hn", "ms" !important;
    margin: 0 !important;
    line-height: 1.8 !important;
    min-height: 3.36rem;
}

.table {
    border-collapse: separate;
    border-spacing: 0 16px;
}

.table td {
    font: 1.87rem "hn", "ms";
    line-height: 1.8;
    color: #8a8a8a;
}

.table td:first-child {
    font: bold 2.25rem "hn", "ms";
    padding: 0 1.25rem 0 0;
    color: #006ab7;
    white-space: nowrap;
    width: 1%;
}

@media (width <= 440px) {
    .careerPost {
        padding: 40px 16px 80px 16px;
        width: calc(100vw - 32px);
    }

    .careerPost > * {
        font: 14px "hn", "ms";
        line-height: 1.8;
    }

    .title {
        font: bold 16px "hn", "ms";
        margin-bottom: 18px;
    }

    .subtitle {
        font: 14px "hn", "ms";
        margin-bottom: 24px;
    }

    .sectionTitle::before {
        width: 2px;
        height: 14px;
        margin-right: 8px;
    }

    .sectionTitle {
        font: bold 16px "hn", "ms";
        height: auto;
        display: flex;
        align-items: center;
        margin: 0 0 24px 0;
    }

    .sectionSubTitle {
        font: normal 16px "hn", "ms";
        color: #006ab7;
        height: auto;
        display: flex;
        align-items: center;
        margin: 0 0 12px 0;
    }

    .paragraph {
        font-size: 14px !important;
        min-height: 25.2px;
    }

    .table {
        border-collapse: separate;
        border-spacing: 0 6px;
    }

    .table td {
        font: 14px "hn", "ms";
    }

    .table td:first-child {
        font: normal 16px "hn", "ms";
        padding: 0 12px 0 0;
    }
}
