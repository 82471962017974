.news {
    padding: 23rem 0 12rem 0;
    width: 123.25rem;
    margin: 0 calc(50% - 61.62rem);
    text-align: center;
}
@media (width <= 440px) {
    .news {
        width: auto;
        margin: 0;
        padding: 56px 16px;
    }
}
.title {
    margin-bottom: 23rem;
}

.newsWrap {
    display: flex;
    gap: 9.12rem 2.3rem;
    flex-wrap: wrap;
    text-align: left;
}

@media (width <= 440px) {
    .newsWrap {
        gap: 48px;
    }
}


.loadMoreBtn {
    display: inline-block;
    font: bold 2rem "nsw";
    background-color: #006ab7;
    color: #fff;
    border-radius: 5px;
    padding: 1rem 2rem;
    cursor: pointer;
    margin-top: 8rem;
}

@media (width <= 440px) {
    .loadMoreBtn {
        font: bold 18px "nsw";
        padding: 8px 24px;
        margin-top: 48px;
    }
}