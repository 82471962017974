.brands {
    margin-bottom: 11.872rem;
}

.banner {
    width: 160rem;
    margin: 0 calc(50% - 80rem);
    margin-bottom: 10rem;
}

.desc {
    text-align: center;
    width: 106rem;
    font: lighter 2.5rem "hn";
    margin: 0 calc(50% - 53rem) !important;
    margin-bottom: 12.64rem !important;
}

@media (width <= 440px) {
    .desc {
        font-size: 14px;
        padding: 0 16px;
    }
}
