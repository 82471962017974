.Heading {
    font: 4.37rem "hn", "ms";
    line-height: 1.28;
    margin-bottom: 3.2rem;
}

.Heading2 {
    font: 3.5rem "hn", "ms";
    line-height: 1.28;
    margin-bottom: 2.56rem;
}

.Paragraph {
    text-align: left;
    font: lighter 2.18rem "hn", "ms";
    line-height: 1.5;
    margin: 1.09rem 0 !important;
    min-height: 2.18rem;
}

.ImgCaption {
    font: lighter 2.18rem "hn", "ms";
    color: #9d9d9d;
    line-height: 1.85;
    margin-bottom: 9.28rem;
}

.Img {
}

.List {
}

.Table {
}

.Hr {
}

@media (width <= 440px) {
    .Heading {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .Paragraph {
        font-size: 14px;
        margin: 7px 0 !important;
    }

    .ImgCaption {
        font-size: 12px;
        word-break: keep-all;
        text-align: center;
    }

    .Img {
        width: 100%;
    }

    .List {
    }

    .Table {
    }

    .Hr {
    }
}
