/* Modile */
@media (width <= 440px) {
    .topbar {
        width: 100vw;
        background-color: #fff;
        padding: 16px;
        gap: 16px;
        align-items: center;
    }

    .topbar * {
        --text-color: #000 !important;
    }

    .hamburgerBtn {
        --btn-width: 20px;
        --btn-height: 15px;
        --line-width: 1.6px;
        --gap: 5px;
    }

    .hamburgerBtn::after,
    .hamburgerBtn::before {
        transition: transform 0s;
    }

    .logoWrap div {
        height: 24px;
    }
}

/* Desktop, Tablet */
@media (width > 440px) {
    .topbar {
        width: 160rem;
        padding: 3.68rem 3.84rem 0 6rem;
        margin: 0 calc(50% - 80rem);
        gap: 1.5rem;
        position: absolute;
        top: 0;
        align-items: flex-start;
    }
    .hamburgerBtn {
        --btn-width: 3.75rem;
        --btn-height: 2.5rem;
        --line-width: 0.5rem;
        --gap: 0.5rem;
    }

    .hamburgerBtn::after,
    .hamburgerBtn::before {
        transition: transform 0.3s;
    }

    .logoWrap div {
        width: 6rem;
    }
}

.topbar {
    display: flex;
    z-index: 999;

    box-sizing: border-box;
}

.topbar.white {
    --text-color: #fff;
}
.topbar.gray {
    --text-color: #9d9d9d;
}
.topbar.black {
    --text-color: #000;
}
.topbar.fixed {
    position: fixed;
    top: 0;
    left: 0;
}

.logoWrap {
    margin: 0 auto 0 0;
    justify-self: center;
}

.hamburgerBtn {
    width: var(--btn-width);
    height: var(--btn-height);
    position: relative;
    cursor: pointer;
}

.hamburgerBtn::after {
    content: "";
    display: inline-block;
    width: var(--btn-width);
    border-top: var(--line-width) var(--text-color) solid;
    position: absolute;
    top: calc(var(--line-width) * 2 + var(--gap) * 2);
}

.hamburgerBtn::before {
    content: "";
    display: inline-block;
    height: var(--gap);
    width: var(--btn-width);
    border: var(--line-width) solid var(--text-color);
    border-right: 0;
    border-left: 0;
    position: absolute;
    top: 0;
}

.hamburgerBtn.menuOpen::after {
    transform: rotate(45deg);
    top: calc(var(--btn-height) / 2);
}

.hamburgerBtn.menuOpen::before {
    border-top: var(--line-width) var(--text-color) solid;
    border-bottom: 0;
    width: var(--btn-width);
    height: 0;
    position: absolute;
    top: calc(var(--btn-height) / 2);

    transform: rotate(-45deg);
}
