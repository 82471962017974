/* Modile */
@media (width <= 440px) {
    .menu {
        padding: 40px 16px;
        width: calc(100vw - 32px);
    }
    .menu.menuOpen {
        top: 56px;
    }

    .menuWrap {
        width: 100%;
        gap: 24px;
        font: 500 24px "nsw";
    }
    .menuItem {
        width: 100%;
        gap: 0 12px;
        flex-wrap: wrap;
    }
    .menuItem .dropDownBtn {
        margin-left: auto;
    }
    .brandDetailDropdown {
        width: 100%;

        padding: 0 0 0 42px;
        font: 500 14px "nsw";
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .brandDetailDropdown.open {
        padding: 12px 0 0 42px;
    }
    .dropDownBtn img {
        transition: transform 0.5s;
    }
    .dropDownBtn.open img {
        transform: rotateX(180deg);
    }
    .menuItemImg {
        width: 28px;
    }
}

/* Desktop */
@media (width > 440px) {
    .menu {
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    .menu.menuOpen {
        top: 0;
    }
    .menuWrap {
        width: 43.75rem;
        gap: 3.83rem;
    }
    .menuItem {
        gap: 1.87rem;
        font-size: 4.75rem;
        font-family: "nsw";
    }
    .menuItem:hover {
        gap: 2.62rem;
        transition: all 0.3s;
    }
    .menuItemImg {
        width: 3.2rem;
    }
}

.menu {
    position: fixed;
    left: 0;
    top: -200vh;
    height: 100vh;
    background-color: #fff;
    z-index: 998;
    display: flex;
    flex-direction: column;
    transition: top 0.5s cubic-bezier(0.16, 0, 0.21, 1);
}

html:has(.menu.menuOpen)::-webkit-scrollbar {
    display: none;
}
html:has(.menu.menuOpen) {
    overflow: hidden;
}

.menuWrap {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.menuItem {
    display: flex;
    align-items: center;
}

.langToggle {
    margin: 56px 24px;
    color: #9d9d9d !important;
    align-self: flex-end;
}
