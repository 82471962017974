.wrap:hover {
    text-decoration: underline;
}

.title {
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* --------- home ----------*/

.wrap.home {
    width: 50rem;
}

.wrap.home .title {
    margin: 2.9rem 0 2rem 0;
    font-family: "hn";
    font-size: 2.56rem;
    line-height: 1.3;
    text-size-adjust: none;
}

.wrap.home .date {
    font-family: "hn";
    font-size: 1.75rem;
    color: #636363;
}

.wrap.home .imgWrap {
    width: 100%;
    aspect-ratio: 1 / 1;
}

.wrap.home .imgWrap img {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
}

@media (width <= 440px) {
    .wrap.home {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 146px 1fr;
        gap: 12px;
        grid-template-areas:
            "img title"
            "img date";
    }

    .wrap.home .imgWrap {
        grid-area: img;
    }

    .wrap.home .title {
        font-family: "hn";
        font-size: 14px;
        line-height: 1.3;

        grid-area: title;
        align-self: end;
    }

    .wrap.home .date {
        font-family: "hn";
        font-size: 12px;
        color: #636363;

        grid-area: date;
        align-self: start;
    }
}

/* --------- brand Related ----------*/
.wrap.brandRelated {
    width: 18.28vw;
}

.wrap.brandRelated .imgWrap {
    aspect-ratio: 1 / 1;
    margin-bottom: 1vw;
}
.wrap.brandRelated .imgWrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.wrap.brandRelated .title {
    font: lighter 1.87rem "ms";
}

.wrap.brandRelated .date {
    display: none;
}

@media (width <= 440px) {
    .wrap.brandRelated {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 2fr 3fr;
        gap: 12px;
        grid-template-areas:
            "img title"
            "img date";
    }

    .wrap.brandRelated .imgWrap {
        grid-area: img;
        height: auto;
    }

    .wrap.brandRelated .title {
        font-family: "ms";
        font-size: 14px;
        line-height: 1.3;

        grid-area: title;
        align-self: end;
    }

    .wrap.brandRelated .date {
        display: block;
        font-family: "hn";
        font-size: 12px;
        color: #636363;

        grid-area: date;
        align-self: start;
    }
}

/* --------- news page ----------*/

.wrap.newsPage {
    width: 39.5rem;
    display: flex;
    flex-direction: column;
}
@media (width <= 440px) {
    .wrap.newsPage {
        width: 100%;
    }
}

.wrap.newsPage .imgWrap {
    aspect-ratio: 1 / 1;
    width: 100%;
}
@media (width <= 440px) {
    .wrap.newsPage .imgWrap {
        width: calc(100vw - 32px);
    }
}

.wrap.newsPage .imgWrap img {
    object-fit: cover;
    height: 100%;
}
@media (width <= 440px) {
    .wrap.newsPage .imgWrap img {
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
    }
}

.wrap.newsPage .title {
    font: 2.31rem "hn";
    margin: 2rem 0;
    word-break: keep-all;
}
@media (width <= 440px) {
    .wrap.newsPage .title {
        font-size: 16px;
        margin: 24px 0 16px 0;
    }
}

.wrap.newsPage .date {
    font: 1.62rem "hn";
    color: #636363;
    margin-top: auto;
}
@media (width <= 440px) {
    .wrap.newsPage .date {
        font-size: 12px;
    }
}
