/* Mobile */
@media (max-width: 440px) {
    .topBtn {
        width: 36px;
        right: 24px;
        bottom: 28px;
    }
}

/* Desktop */
@media (min-width: 440px) {
    .topBtn {
        width: 3.5rem;
        right: 4rem;
        bottom: 3rem;
    }
}

.topBtn {
    position: fixed;
    cursor: pointer;
}

.topBtn img {
    width: 100%;
}
