.Loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

@keyframes bounce {
    0% {
        padding-bottom: 0;
    }
    50% {
        padding-bottom: 24px;
    }
    100% {
        padding-bottom: 0;
    }
}

.acorn {
    width: 36px;
    height: auto;
    animation: bounce 1s ease;
    animation-iteration-count: infinite;
}

.acorn:nth-child(1) {
    animation-delay: calc(1s / 6);
}
.acorn:nth-child(2) {
    animation-delay: calc(2s / 6);
}
.acorn:nth-child(3) {
    animation-delay: calc(3s / 6);
}
