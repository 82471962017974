.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 17.9vw;
}
.logo {
    width: 40rem;
    margin: 23rem 0 7.3rem 0;
}
.moto {
    font: 3.75rem "nsw";
    margin-bottom: 9.25rem;
    text-align: center;
}
.desc {
    text-align: center;
    font: lighter 2.5rem "hn";
    line-height: 1.3;
    margin-bottom: 11.58rem;
}
.people {
    margin-bottom: 11.36rem;
    width: 100rem;
}
@media (width <= 440px) {
    .about {
        padding: 0 16px;
    }
    .logo {
        width: 172px;
        margin: 40px 0 48px 0;
    }
    .moto {
        font: 500 26px "nsw";
        margin-bottom: 40px;
        text-align: center;
    }
    .desc {
        font: 14px "hn";
        line-height: 1.3;
        margin-bottom: 72px;
    }
    .people {
        margin: 0 -8px;
        margin-bottom: 80px;
        width: auto;
    }
}

.person {
    width: 33%;
    padding: 3.2rem 2.56rem;
    box-sizing: border-box;

    display: inline-flex;
    flex-direction: column;
    position: relative;
    gap: 0.64rem;
}
@media (width <= 440px) {
    .person {
        width: 50%;
        padding: 16px 8px;
    }
}
.person.selected {
    color: #006ab7;
}
.profileImgWrap {
    width: 100%;
    margin-bottom: 1.6rem;
}
@media (width <= 440px) {
    .profileImgWrap {
        margin-bottom: 16px;
    }
}
.name {
    font: bold 2.9rem "nsw";
}
@media (width <= 440px) {
    .name {
        font-size: 16px;
        margin-bottom: 8px;
    }
}
.role,
.learnMoreBtn {
    font: 1.8rem "hn";
    color: inherit;
}
@media (width <= 440px) {
    .role,
    .learnMoreBtn {
        font-size: 14px;
        margin-bottom: 8px;
    }
}
.learnMoreBtn {
    margin-top: auto;
    cursor: pointer;
}

.learnMoreBtn img {
    margin: 0 0 0.2rem 1rem;
    height: 0.8rem;
    transition: all 0.3s;
}
@media (width <= 440px) {
    .learnMoreBtn img {
        height: 7px;
        margin: 0 0 0 20px;
    }
}

.person.selected .learnMoreBtn img {
    transform: rotate(-180deg);
}

.person:not(.selected) .role {
    color: #8a8a8a;
}
.person:not(.selected) .learnMoreBtn {
    color: #00aeef;
}

.learnMore {
    display: block;
    width: 100%;
    box-sizing: border-box;
    float: left;
    max-height: 0px;
    overflow: hidden;
    font: 1.68rem "hn";
    line-height: 2.18rem;
    padding: 0 10.3rem;
    border: 1px solid transparent;
    border-left: 0;
    border-right: 0;
}
@media (width <= 440px) {
    .learnMore {
        font: lighter 14px "ms";
        width: calc(100vw - 16px);
        box-sizing: border-box;
    }
}
.learnMore.open {
    max-height: 1500px;
    padding: 2.8rem 10.3rem;
    border: 1px solid #b2b1ad;
    border-left: 0;
    border-right: 0;
    transition: max-height 0.5s ease;
}
@media (width <= 440px) {
    .learnMore.open {
        padding: 32px 8px;
    }
}

.learnMore.close {
    transition: none;
}

.learnMore p {
    margin: 1em 0;
}
