:root {
    --map-mobile-height: calc((100vw - 32px) * 0.75);
}

.mapWrapper {
    flex: 1;
    height: auto;
}

.mapShadow {
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.1));
}
@media (width <= 440px) {
    .mapShadow {
        filter: none;
    }
}
.rawMap {
    width: 100%;
    height: 41.5rem;
}
@media (width <= 440px) {
    .rawMap {
        height: var(--map-mobile-height);
    }
}
.mapLoading {
    width: 100%;
    height: 41.5rem;
    font: lighter 2rem "hn";
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9d9d9;
    text-align: center;
}
@media (width <= 440px) {
    .mapLoading {
        height: var(--map-mobile-height);
    }
}
.mapText {
    margin-top: 2.26rem;
}
@media (width <= 440px) {
    .mapText {
        margin-top: 32px;
    }
}
.locationTitle {
    font: 3.75rem "nsw";
    display: flex;
    align-items: center;
}
@media (width <= 440px) {
    .locationTitle {
        font-size: 20px;
    }
}

.locationTitle > img {
    margin-right: 1.36rem;
    width: 2.3rem;
}
@media (width <= 440px) {
    .locationTitle > img {
        width: 15px;
        margin-right: 15px;
    }
}

.locationText {
    font: 2.5rem "hn";
    color: #8a8a8a;
    margin: 1.87rem 0 0 3.66rem;
}
@media (width <= 440px) {
    .locationText {
        font-size: 14px;
        margin: 16px 0 0 30px;
    }
}
