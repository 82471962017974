.langToggle {
    font-family: "nsw";
    font-weight: bold;
    color: var(--text-color);
    display: flex;
    font-size: 2rem;
    gap: 1vw;
}
@media (width <= 440px) {
    .langToggle {
        font-size: 18px;
        gap: 12px;
    }
}
.eng {
    cursor: pointer;
}
.kor {
    cursor: pointer;
}
